import React, { Fragment } from 'react'
import { Row, Col } from 'react-bootstrap'
import SentimentAnalysisChart from '../components/reports/SentimentAnalysisChart'
import SentimentAnalysisPieChart from '../components/reports/SentimentAnalysisPieChart'
import ClassificationReport from '../components/reports/ClassificationReport'
import AudienceProfileReport from '../components/reports/AudienceProfileReport'
import ExtractionReport from '../components/reports/ExtractionReport'
import SummarisationReport from '../components/reports/SummarisationReport'


const Reports = () => {

    return (
        <Fragment>
            <Row>
                <SentimentAnalysisChart name={'Sentiment Analysis'}/>
                <SentimentAnalysisPieChart name={'Generative AI Sentiment'}/>
            </Row>
            <Row>
                <Col md={12}>
                    <AudienceProfileReport />
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={6}>
                    <ClassificationReport />
                </Col>
                <Col md={12} lg={6}>
                    <ExtractionReport />
                </Col>
                <Col md={12}>
                    <SummarisationReport />
                </Col>
            </Row>
        </Fragment>
    )
}

export default Reports