import React, { Fragment, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { gql, useQuery } from '@apollo/client'
import Card from '../common/Card'
import Loader from '../../layouts/components/Loader'
import 'flatpickr/dist/flatpickr.css'

const GET_SUMMARY = gql`
    query Campaigns {
        campaigns {
            name
            audienceProfile
        }
    }`

const AudienceProfileReport = () => {
    const campaign = useQuery(GET_SUMMARY, {
        onCompleted(data) {
            if (data.campaigns[0]?.audienceProfile) {
                const source = data.campaigns[0]?.audienceProfile
                setText(source.split('\n'))
            }
        }
    })
    const [text, setText] = useState([])

    const showTextGroup = (number) => {
        return text.length > 0
            ? (text[number] && text[number].split(': ')[1] ? text[number].split(': ')[1] : 'No Data')
            : 'In Progress...'
    }

    return (
        <Card>
            {campaign.loading  ? (
                <Loader fullScreen={false} />
            ) : (
                <Fragment>
                    <Card.Body className="card-thumbnail">
                        <div>
                            <h4 className="mt-2">Generative AI Audience Profile</h4>
                            <Row>
                                <Col md={12} xl={4}>
                                    <div className="d-flex">
                                        <div className="bg-soft-primary rounded p-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"/>
                                            </svg>
                                        </div>
                                        <h5 style={{marginLeft: '20px', marginTop: '15px'}}>
                                            <span>Age Group</span>
                                        </h5>
                                    </div>
                                    <div className="three-lines-with-scroll">
                                        <p>{showTextGroup(0)}</p>
                                    </div>
                                    <hr style={{color: '#c1c1c1', border: '0.5px solid'}}/>
                                    <div className="d-flex">
                                        <div className="rounded p-3 bg-soft-warning">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"/>
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"/>
                                            </svg>
                                        </div>
                                        <h5 style={{marginLeft: '20px', marginTop: '15px'}}>
                                            <span>Gender Group</span>
                                        </h5>
                                    </div>
                                    <div className="three-lines-with-scroll">
                                        <p>{showTextGroup(1)}</p>
                                    </div>
                                </Col>
                                <Col md={12} xl={4}>
                                    <div className="d-flex">
                                        <div className="rounded p-3 bg-soft-success">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"/>
                                            </svg>
                                        </div>
                                        <h5 style={{marginLeft: '20px', marginTop: '15px'}}>
                                            <span>Family Status</span>
                                        </h5>
                                    </div>
                                    <div className="three-lines-with-scroll">
                                        <p>{showTextGroup(2)}</p>
                                    </div>
                                    <hr style={{color: '#c1c1c1', border: '0.5px solid'}}/>
                                    <div className="d-flex">
                                        <div className="rounded p-3 bg-soft-info">
                                            <svg width="24" height="24" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M9.5,13.09L10.91,14.5L6.41,19H10V21H3V14H5V17.59L9.5,13.09M10.91,9.5L9.5,10.91L5,6.41V10H3V3H10V5H6.41L10.91,9.5M14.5,13.09L19,17.59V14H21V21H14V19H17.59L13.09,14.5L14.5,13.09M13.09,9.5L17.59,5H14V3H21V10H19V6.41L14.5,10.91L13.09,9.5Z"/>
                                            </svg>
                                        </div>
                                        <h5 style={{marginLeft: '20px', marginTop: '15px'}}>
                                            <span>Behaviour</span>
                                        </h5>
                                    </div>
                                    <div className="three-lines-with-scroll">
                                        <p>{showTextGroup(3)}</p>
                                    </div>
                                </Col>
                                <Col md={12} xl={4}>
                                    <div className="d-flex">
                                        <div className="rounded p-3 bg-soft-danger">
                                            <svg width="24" height="24" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22A9,9 0 0,0 21,13A9,9 0 0,0 12,4M12.5,8H11V14L15.75,16.85L16.5,15.62L12.5,13.25V8M7.88,3.39L6.6,1.86L2,5.71L3.29,7.24L7.88,3.39M22,5.72L17.4,1.86L16.11,3.39L20.71,7.25L22,5.72Z"/>
                                            </svg>
                                        </div>
                                        <h5 style={{marginLeft: '20px', marginTop: '15px'}}>
                                            <span>Life Events</span>
                                        </h5>
                                    </div>
                                    <div className="three-lines-with-scroll">
                                        <p>{showTextGroup(4)}</p>
                                    </div>
                                    <hr style={{color: '#c1c1c1', border: '0.5px solid'}}/>
                                    <div className="d-flex">
                                        <div className="rounded p-3 bg-soft-success me-3">
                                            <svg width="24" height="24" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M19.07,4.93L17.66,6.34C19.1,7.79 20,9.79 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12C4,7.92 7.05,4.56 11,4.07V6.09C8.16,6.57 6,9.03 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12C18,10.34 17.33,8.84 16.24,7.76L14.83,9.17C15.55,9.9 16,10.9 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12C8,10.14 9.28,8.59 11,8.14V10.28C10.4,10.63 10,11.26 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12C14,11.26 13.6,10.62 13,10.28V2H12A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,9.24 20.88,6.74 19.07,4.93Z"/>
                                            </svg>
                                        </div>
                                        <h5 style={{marginLeft: '20px', marginTop: '15px'}}>
                                            <span>Interests</span>
                                        </h5>
                                    </div>
                                    <div className="three-lines-with-scroll">
                                        <p>{showTextGroup(5)}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>
                </Fragment>
            )}
        </Card>
    )
}

export default AudienceProfileReport