import { Col } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import Card from '../common/Card'

const ConversionRateBarChart = ({ name, data, colour, creatives, rates }) => {
    let sales = {}
    let conversion = {}

    if (data.salesPerAds) {
        sales = data.salesPerAds
        conversion = data.conversionPerAds
    } else {
        data.landingPagesConnection.edges.forEach(edge => {
            const landingPageId = edge.node.id

            sales[landingPageId] = edge.sales || 0
            conversion[landingPageId] = edge.conversionRate || 0
        })
    }

    const labels = conversion
        ? Object.keys(conversion).map((item) => creatives.find((creative) => creative.id === item).title)
        : creatives.map((creative) => creative.title)

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            }
        },
        colors: [colour],
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: labels
        },
        grid: {
            show: false
        },
    }

    const getData = () => {
        if (rates) {
            return conversion ? Object.values(conversion).map((item) => item ? item.toFixed(1) : 0) : new Array(creatives.length).fill(0)
        } else {
            return sales ? Object.values(sales).map((item) => item ? item.toFixed(1) : 0) : new Array(creatives.length).fill(0)
        }
    }

    const series = [{
        name: rates ? 'Conversion Rate (%)' : 'Sales ($)',
        data: getData()
    }]

    return (
        <Col md={12} xl={4}>
            <Card data-aos="fade-up" data-aos-delay="1000">
                <div className="flex-wrap card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">{name}</h4>
                    </div>
                </div>
                <Card.Body>
                    <Chart className="d-activity" options={options} series={series} type="bar" height="300" />
                </Card.Body>
            </Card>
        </Col>
    )
}

export default ConversionRateBarChart