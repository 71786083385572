import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Row, Col } from 'react-bootstrap'
import AnalyticsCard from '../components/campaigns/AnalyticsCard'
import ConversionCard from '../components/campaigns/ConversionCard'
import MetricCard from '../components/campaigns/MetricCard'
import Card from '../components/common/Card'
import ProgressBar from '../components/common/ProgressBar'
import ConversionsChart from '../components/campaigns/ConversionsChart'
import CampaignCostChart from '../components/campaigns/CampaignCostChart'
import PublisherPlatformChart from '../components/campaigns/PublisherPlatformChart'
import ConversionRateBarChart from '../components/campaigns/ConversionRateBarChart'
import { ToastContainer } from 'react-toastify'
import Loader from '../layouts/components/Loader'
import { showNotification } from '../utils/notifications'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.css'
import {cloneDeep} from "@apollo/client/utilities";

const GET_INSIGHTS = gql`
    query Publishers($publishersWhere: PublisherWhere, $adCampaignsWhere: AdCampaignWhere, $options: AdCampaignOptions) {
        publishers(where: $publishersWhere) {
            name
            adCampaigns(where: $adCampaignsWhere, options: $options) {
                addToCart
                addToCartValue
                conversion
                conversionsByMonths
                costPerConversion
                updatedAt
                engagement
                impressions
                uniqueCtr
                publishers
                purchase
                purchaseValue
                reach
                spend
                createdAt
                landingPagesConnection {
                    edges {
                        sales
                        conversionRate
                        node {
                            id
                        }
                    }
                }
            }
        }
    }`

const GET_LANDING_PAGES = gql`
    query LandingPages($where: LandingPageWhere) {
        landingPages(where: $where) {
            id
            title
        }
    }`

const GET_CUSTOM_INSIGHTS = gql`
    query PublisherCustomInsights($from: DateTime!, $to: DateTime!) {
        publisherCustomInsights(from: $from, to: $to) {
            id
            name
            addToCart
            addToCartValue
            conversion
            costPerConversion
            engagement
            impressions
            uniqueCtr
            publishers
            purchase
            purchaseValue
            reach
            spend
        }
    }`

const roasIcon = (
    <svg width="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M2 11.0786C2.05 13.4166 2.19 17.4156 2.21 17.8566C2.281 18.7996 2.642 19.7526 3.204 20.4246C3.986 21.3676 4.949 21.7886 6.292 21.7886C8.148 21.7986 10.194 21.7986 12.181 21.7986C14.176 21.7986 16.112 21.7986 17.747 21.7886C19.071 21.7886 20.064 21.3566 20.836 20.4246C21.398 19.7526 21.759 18.7896 21.81 17.8566C21.83 17.4856 21.93 13.1446 21.99 11.0786H2Z" fill="currentColor"></path>
        <path d="M11.2451 15.3843V16.6783C11.2451 17.0923 11.5811 17.4283 11.9951 17.4283C12.4091 17.4283 12.7451 17.0923 12.7451 16.6783V15.3843C12.7451 14.9703 12.4091 14.6343 11.9951 14.6343C11.5811 14.6343 11.2451 14.9703 11.2451 15.3843Z" fill="currentColor"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.211 14.5565C10.111 14.9195 9.762 15.1515 9.384 15.1015C6.833 14.7455 4.395 13.8405 2.337 12.4815C2.126 12.3435 2 12.1075 2 11.8555V8.38949C2 6.28949 3.712 4.58149 5.817 4.58149H7.784C7.972 3.12949 9.202 2.00049 10.704 2.00049H13.286C14.787 2.00049 16.018 3.12949 16.206 4.58149H18.183C20.282 4.58149 21.99 6.28949 21.99 8.38949V11.8555C21.99 12.1075 21.863 12.3425 21.654 12.4815C19.592 13.8465 17.144 14.7555 14.576 15.1105C14.541 15.1155 14.507 15.1175 14.473 15.1175C14.134 15.1175 13.831 14.8885 13.746 14.5525C13.544 13.7565 12.821 13.1995 11.99 13.1995C11.148 13.1995 10.433 13.7445 10.211 14.5565ZM13.286 3.50049H10.704C10.031 3.50049 9.469 3.96049 9.301 4.58149H14.688C14.52 3.96049 13.958 3.50049 13.286 3.50049Z" fill="currentColor"></path>
    </svg>
)

const ctrIcon = (
    <svg width="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.4274 2.5783C20.9274 2.0673 20.1874 1.8783 19.4974 2.0783L3.40742 6.7273C2.67942 6.9293 2.16342 7.5063 2.02442 8.2383C1.88242 8.9843 2.37842 9.9323 3.02642 10.3283L8.05742 13.4003C8.57342 13.7163 9.23942 13.6373 9.66642 13.2093L15.4274 7.4483C15.7174 7.1473 16.1974 7.1473 16.4874 7.4483C16.7774 7.7373 16.7774 8.2083 16.4874 8.5083L10.7164 14.2693C10.2884 14.6973 10.2084 15.3613 10.5234 15.8783L13.5974 20.9283C13.9574 21.5273 14.5774 21.8683 15.2574 21.8683C15.3374 21.8683 15.4274 21.8683 15.5074 21.8573C16.2874 21.7583 16.9074 21.2273 17.1374 20.4773L21.9074 4.5083C22.1174 3.8283 21.9274 3.0883 21.4274 2.5783Z" fill="currentColor"></path>
        <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M3.01049 16.8079C2.81849 16.8079 2.62649 16.7349 2.48049 16.5879C2.18749 16.2949 2.18749 15.8209 2.48049 15.5279L3.84549 14.1619C4.13849 13.8699 4.61349 13.8699 4.90649 14.1619C5.19849 14.4549 5.19849 14.9299 4.90649 15.2229L3.54049 16.5879C3.39449 16.7349 3.20249 16.8079 3.01049 16.8079ZM6.77169 18.0003C6.57969 18.0003 6.38769 17.9273 6.24169 17.7803C5.94869 17.4873 5.94869 17.0133 6.24169 16.7203L7.60669 15.3543C7.89969 15.0623 8.37469 15.0623 8.66769 15.3543C8.95969 15.6473 8.95969 16.1223 8.66769 16.4153L7.30169 17.7803C7.15569 17.9273 6.96369 18.0003 6.77169 18.0003ZM7.02539 21.5683C7.17139 21.7153 7.36339 21.7883 7.55539 21.7883C7.74739 21.7883 7.93939 21.7153 8.08539 21.5683L9.45139 20.2033C9.74339 19.9103 9.74339 19.4353 9.45139 19.1423C9.15839 18.8503 8.68339 18.8503 8.39039 19.1423L7.02539 20.5083C6.73239 20.8013 6.73239 21.2753 7.02539 21.5683Z" fill="currentColor"></path>
    </svg>
)

const event2Icon = (
    <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.9964 8.37513H17.7618C15.7911 8.37859 14.1947 9.93514 14.1911 11.8566C14.1884 13.7823 15.7867 15.3458 17.7618 15.3484H22V15.6543C22 19.0136 19.9636 21 16.5173 21H7.48356C4.03644 21 2 19.0136 2 15.6543V8.33786C2 4.97862 4.03644 3 7.48356 3H16.5138C19.96 3 21.9964 4.97862 21.9964 8.33786V8.37513ZM6.73956 8.36733H12.3796H12.3831H12.3902C12.8124 8.36559 13.1538 8.03019 13.152 7.61765C13.1502 7.20598 12.8053 6.87318 12.3831 6.87491H6.73956C6.32 6.87664 5.97956 7.20858 5.97778 7.61852C5.976 8.03019 6.31733 8.36559 6.73956 8.36733Z" fill="currentColor"></path>
        <path opacity="0.4" d="M16.0374 12.2966C16.2465 13.2478 17.0805 13.917 18.0326 13.8996H21.2825C21.6787 13.8996 22 13.5715 22 13.166V10.6344C21.9991 10.2297 21.6787 9.90077 21.2825 9.8999H17.9561C16.8731 9.90338 15.9983 10.8024 16 11.9102C16 12.0398 16.0128 12.1695 16.0374 12.2966Z" fill="currentColor"></path>
        <circle cx="18" cy="11.8999" r="1" fill="currentColor"></circle>
    </svg>
)

const event1Icon = (
    <svg width="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M5.91064 20.5886C5.91064 19.7486 6.59064 19.0686 7.43064 19.0686C8.26064 19.0686 8.94064 19.7486 8.94064 20.5886C8.94064 21.4186 8.26064 22.0986 7.43064 22.0986C6.59064 22.0986 5.91064 21.4186 5.91064 20.5886ZM17.1606 20.5886C17.1606 19.7486 17.8406 19.0686 18.6806 19.0686C19.5106 19.0686 20.1906 19.7486 20.1906 20.5886C20.1906 21.4186 19.5106 22.0986 18.6806 22.0986C17.8406 22.0986 17.1606 21.4186 17.1606 20.5886Z" fill="currentColor"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.1907 6.34909C20.8007 6.34909 21.2007 6.55909 21.6007 7.01909C22.0007 7.47909 22.0707 8.13909 21.9807 8.73809L21.0307 15.2981C20.8507 16.5591 19.7707 17.4881 18.5007 17.4881H7.59074C6.26074 17.4881 5.16074 16.4681 5.05074 15.1491L4.13074 4.24809L2.62074 3.98809C2.22074 3.91809 1.94074 3.52809 2.01074 3.12809C2.08074 2.71809 2.47074 2.44809 2.88074 2.50809L5.26574 2.86809C5.60574 2.92909 5.85574 3.20809 5.88574 3.54809L6.07574 5.78809C6.10574 6.10909 6.36574 6.34909 6.68574 6.34909H20.1907ZM14.1307 11.5481H16.9007C17.3207 11.5481 17.6507 11.2081 17.6507 10.7981C17.6507 10.3781 17.3207 10.0481 16.9007 10.0481H14.1307C13.7107 10.0481 13.3807 10.3781 13.3807 10.7981C13.3807 11.2081 13.7107 11.5481 14.1307 11.5481Z" fill="currentColor"></path>
    </svg>
)

const Campaign = () => {
    const fp = useRef(null)
    const [data, setData] = useState({
        spend: 0,
        conversionsByMonths: [],
        landingPagesConnection: {
            edges: []
        }
    })
    const [period, setPeriod] = useState(null)

    const [getCustomInsights, customInsights] = useLazyQuery(GET_CUSTOM_INSIGHTS)
    const creatives = useQuery(GET_LANDING_PAGES)
    const insights = useQuery(GET_INSIGHTS, {
        variables: {
            adCampaignsWhere: {},
            options: { sort: [{ createdAt: 'ASC' }]}
        }
    })

    const combineData = useCallback((data) => {
        const combinedData = {}

        // Filter out Ad Campaigns without CTR data.
        const ctrData = data.filter((item) => item.uniqueCtr)

        // Calculate CTR as a weighted average based on impressions.
        combinedData.uniqueCtr = (ctrData.reduce((sum, item) => sum + item.uniqueCtr * item.impressions, 0)) / (ctrData.reduce((sum, item) => sum + item.impressions, 0))

        combinedData.createdAt = data.reduce((acc, obj) => acc < new Date(obj.createdAt) ? acc : new Date(obj.createdAt), new Date(data[0].createdAt))
        combinedData.addToCart = data.reduce((acc, obj) => acc + obj.addToCart, 0)
        combinedData.addToCartValue = data.reduce((acc, obj) => acc + obj.addToCartValue, 0)
        combinedData.conversion = data.reduce((acc, obj) => acc + obj.conversion, 0)
        combinedData.engagement = data.reduce((acc, obj) => acc + obj.engagement, 0)
        combinedData.impressions = data.reduce((acc, obj) => acc + obj.impressions, 0)
        combinedData.purchase = data.reduce((acc, obj) => acc + obj.purchase, 0)
        combinedData.purchaseValue = data.reduce((acc, obj) => acc + obj.purchaseValue, 0)
        combinedData.reach = data.reduce((acc, obj) => acc + obj.reach, 0)
        combinedData.spend = data.reduce((acc, obj) => acc + obj.spend, 0)
        combinedData.roas = combinedData.spend > 0 ? combinedData.purchaseValue / combinedData.spend : 0

        combinedData.costPerConversion = combinedData.spend / combinedData.conversion
        combinedData.conversionsByMonths = updateConversionsByMonths([0], new Date())

        combinedData.publishers = {}
        combinedData.salesPerAds = {}
        combinedData.conversionPerAds = {}

        data.forEach((campaign) => {
            const conversionsByMonths = updateConversionsByMonths(campaign.conversionsByMonths ? JSON.parse(campaign.conversionsByMonths) : [], campaign.updatedAt)
            combinedData.conversionsByMonths = combinedData.conversionsByMonths.map((value, index) => value + conversionsByMonths[index])

            const publishers = JSON.parse(campaign.publishers)
            Object.keys(publishers).forEach((key) => {
                if (combinedData.publishers[key] === undefined) combinedData.publishers[key] = 0
                combinedData.publishers[key] += publishers[key]
            })

            const salesPerAds = campaign.landingPagesConnection.edges.reduce((acc, edge) => {
                acc[edge.node.id] = edge.sales
                return acc
            }, {})
            Object.keys(salesPerAds).forEach((key) => {
                if (combinedData.salesPerAds[key] === undefined) combinedData.salesPerAds[key] = 0
                combinedData.salesPerAds[key] += salesPerAds[key]
            })

            const conversionPerAds = campaign.landingPagesConnection.edges.reduce((acc, edge) => {
                acc[edge.node.id] = edge.conversionRate
                return acc
            }, {})
            Object.keys(conversionPerAds).forEach((key) => {
                if (combinedData.conversionPerAds[key] === undefined) combinedData.conversionPerAds[key] = 0
                combinedData.conversionPerAds[key] += conversionPerAds[key]
            })
        })

        Object.keys(combinedData.conversionPerAds).forEach((key) => {
            combinedData.conversionPerAds[key] = combinedData.conversionPerAds[key] / data.length
        })

        setData(combinedData)
    }, [])

    const updateConversionsByMonths = (conversionsByMonths, updatedAt = null) => {
        const maxMonths = 12
        const updatedDate = updatedAt ? new Date(updatedAt) : new Date();
        const currentDate = new Date()

        // Calculate the number of months between the current date and the updated date
        const monthsDifference =
            (currentDate.getFullYear() - updatedDate.getFullYear()) * 12 +
            (currentDate.getMonth() - updatedDate.getMonth())

        // Shift the elements in the array by adding 0s for each month passed
        let updatedConversions = [...conversionsByMonths]

        // Add the required number of 0s based on the monthsDifference
        for (let i = 0; i < monthsDifference; i++) {
            updatedConversions.push(0)
        }

        // Ensure the array has exactly 12 elements by adding 0s to the left if necessary
        while (updatedConversions.length < maxMonths) {
            updatedConversions.unshift(0)
        }

        // If the array exceeds 12 elements, keep only the last 12
        if (updatedConversions.length > maxMonths) {
            updatedConversions = updatedConversions.slice(-maxMonths)
        }

        return updatedConversions
    }

    const defaultPeriod = useCallback((startDate) => {
        const start = startDate ? new Date(startDate) : new Date()
        const end = new Date()
        return start.toLocaleDateString("en-GB", {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }) + ' - ' + end.toLocaleDateString("en-GB", {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        })
    }, [])

    const handleDateChange = async (selectedDates) => {
        // Check if both start and end dates are selected
        if (selectedDates.length === 2) {
            setPeriod(selectedDates[0].toLocaleDateString("en-GB", {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            }) + ' - ' + selectedDates[1].toLocaleDateString("en-GB", {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            }))

            await getCustomInsights({
                variables: {
                    from: selectedDates[0].toISOString().split('T')[0],
                    to: selectedDates[1].toISOString().split('T')[0]
                },
                fetchPolicy: 'network-only',
                onCompleted: (response) => {
                    const data = response.publisherCustomInsights.find((publisher) => publisher.name === 'Meta')
                    const cloneData = cloneDeep(data)
                    cloneData.roas = cloneData.spend > 0 ? cloneData.purchaseValue / cloneData.spend : 0
                    setData((prevData) => ({
                        ...prevData,
                        ...cloneData
                    }))
                }
            })

        }
    }

    const openFlatpickr = () => {
        if (fp.current?.flatpickr) {
            setTimeout(() => fp.current.flatpickr.open(), 100)
        }
    }

    useEffect(() => {
        if (insights.data?.publishers?.length > 0) {
            const data = insights.data.publishers.find((publisher) => publisher.name === 'Meta').adCampaigns
            let startDate = null
            if (data.length > 0) {
                if (data.length > 1) {
                    combineData(data)
                } else {
                    const cloneData = cloneDeep(data[0])
                    cloneData.roas = cloneData.spend > 0 ? cloneData.purchaseValue / cloneData.spend : 0
                    cloneData.uniqueCtr = cloneData.uniqueCtr || 0

                    setData(cloneData)
                }
                startDate = data[0].createdAt
            } else {
                showNotification('Data on this page will appear once media buying starts', 'info', false)
            }
            setPeriod(defaultPeriod(startDate))
        }
    }, [insights.data, combineData, setPeriod, defaultPeriod])

    return (
        <Fragment>
            <ToastContainer/>
            <div className="mb-5">
                <div className="d-flex flex-wrap justify-content-between mb-4">
                    <h3 className="mb-0">Campaign Summary</h3>
                    <div className="d-flex flex-row">
                        <button type="button" className="btn btn-soft-light" onClick={openFlatpickr}>
                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-24" height="24" style={{marginRight: '10px'}}>
                                <path fillRule="evenodd" clipRule="evenodd" d="M3 16.8701V9.25708H21V16.9311C21 20.0701 19.0241 22.0001 15.8628 22.0001H8.12733C4.99561 22.0001 3 20.0301 3 16.8701ZM7.95938 14.4101C7.50494 14.4311 7.12953 14.0701 7.10977 13.6111C7.10977 13.1511 7.46542 12.7711 7.91987 12.7501C8.36443 12.7501 8.72997 13.1011 8.73985 13.5501C8.7596 14.0111 8.40395 14.3911 7.95938 14.4101ZM12.0198 14.4101C11.5653 14.4311 11.1899 14.0701 11.1701 13.6111C11.1701 13.1511 11.5258 12.7711 11.9802 12.7501C12.4248 12.7501 12.7903 13.1011 12.8002 13.5501C12.82 14.0111 12.4643 14.3911 12.0198 14.4101ZM16.0505 18.0901C15.596 18.0801 15.2305 17.7001 15.2305 17.2401C15.2206 16.7801 15.5862 16.4011 16.0406 16.3911H16.0505C16.5148 16.3911 16.8902 16.7711 16.8902 17.2401C16.8902 17.7101 16.5148 18.0901 16.0505 18.0901ZM11.1701 17.2401C11.1899 17.7001 11.5653 18.0611 12.0198 18.0401C12.4643 18.0211 12.82 17.6411 12.8002 17.1811C12.7903 16.7311 12.4248 16.3801 11.9802 16.3801C11.5258 16.4011 11.1701 16.7801 11.1701 17.2401ZM7.09989 17.2401C7.11965 17.7001 7.49506 18.0611 7.94951 18.0401C8.39407 18.0211 8.74973 17.6411 8.72997 17.1811C8.72009 16.7311 8.35456 16.3801 7.90999 16.3801C7.45554 16.4011 7.09989 16.7801 7.09989 17.2401ZM15.2404 13.6011C15.2404 13.1411 15.596 12.7711 16.0505 12.7611C16.4951 12.7611 16.8507 13.1201 16.8705 13.5611C16.8804 14.0211 16.5247 14.4011 16.0801 14.4101C15.6257 14.4201 15.2503 14.0701 15.2404 13.6111V13.6011Z" fill="currentColor"></path>
                                <path opacity="0.4" d="M3.00293 9.25699C3.01577 8.66999 3.06517 7.50499 3.15803 7.12999C3.63224 5.02099 5.24256 3.68099 7.54442 3.48999H16.4555C18.7376 3.69099 20.3677 5.03999 20.8419 7.12999C20.9338 7.49499 20.9832 8.66899 20.996 9.25699H3.00293Z" fill="currentColor"></path>
                                <path d="M8.30465 6.59C8.73934 6.59 9.06535 6.261 9.06535 5.82V2.771C9.06535 2.33 8.73934 2 8.30465 2C7.86996 2 7.54395 2.33 7.54395 2.771V5.82C7.54395 6.261 7.86996 6.59 8.30465 6.59Z" fill="currentColor"></path>
                                <path d="M15.6953 6.59C16.1201 6.59 16.456 6.261 16.456 5.82V2.771C16.456 2.33 16.1201 2 15.6953 2C15.2606 2 14.9346 2.33 14.9346 2.771V5.82C14.9346 6.261 15.2606 6.59 15.6953 6.59Z" fill="currentColor"></path>
                            </svg>
                            {period}
                        </button>
                        <Flatpickr
                            ref={fp}
                            className="hidden-input"
                            onChange={handleDateChange}
                            options={{mode: 'range', showMonths: 2, maxDate: 'today'}}
                        />
                    </div>
                </div>
                { insights.loading || creatives.loading || customInsights.loading ? (
                    <Loader />
                ) : (
                    <Fragment>
                        <Row>
                            <Col lg="6" xl="3" md="6">
                                <AnalyticsCard count={data.engagement} content="Audience Engagement" color="#08B1BA" />
                            </Col>
                            <Col lg="6" xl="3" md="6">
                                <AnalyticsCard count={data.conversion} content="Audience Conversion" color="#427EEB" />
                            </Col>
                            <Col lg="6" xl="3" md="6">
                                <AnalyticsCard count={data.impressions} content="Impressions" color="#08B1BA" />
                            </Col>
                            <Col lg="6" xl="3" md="6">
                                <AnalyticsCard count={data.reach} content="Reach" color="#427EEB" />
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="4">
                                <MetricCard
                                    svg={roasIcon}
                                    value={data.roas}
                                    rate={data.roas / 3 * 100}
                                    colour="bg-soft-primary"
                                    progress="primary"
                                    label="ROAS"
                                    percentage={false}
                                />
                                <MetricCard
                                    svg={ctrIcon}
                                    value={data.uniqueCtr}
                                    rate={data.uniqueCtr / 5 * 100}
                                    colour="bg-soft-info"
                                    progress="info"
                                    label="CTR"
                                    percentage={true}
                                />
                            </Col>

                            <Col lg="4" className="mt-0 mt-sm-md-0">
                                <Card>
                                    <Card.Header>
                                        <h4 className="mb-0">Cost and Efficiency</h4>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col sm="12">
                                                <CampaignCostChart data={data} />
                                            </Col>
                                        </Row>
                                        <div className="d-flex mt-3 mt-sm-0">
                                            <span className="avatar-50 bg-soft-info rounded">
                                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" height="32">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z" fill="currentColor"></path>
                                                    <path opacity="0.4" d="M16.9739 6.77432C16.977 6.85189 16.9621 6.92913 16.9303 7H15.4932C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H7.00989C6.9967 6.92535 6.9967 6.84898 7.00989 6.77432C7.12172 4.10591 9.32499 2 12.0049 2C14.6849 2 16.8882 4.10591 17 6.77432H16.9739Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            <div className="w-100 ms-3">
                                                <div className="d-flex justify-content-between">
                                                    <h6>Conversion Cost</h6>
                                                    <p>${data.costPerConversion ? data.costPerConversion.toFixed(2) : 0}</p>
                                                </div>
                                                <ProgressBar
                                                    softcolors="info"
                                                    color="info"
                                                    className="shadow-none w-100"
                                                    value={data.costPerConversion ? 45 : 0}
                                                    minvalue={0}
                                                    maxvalue={100}
                                                    style={{height: "8px"}}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg="4">
                                <ConversionCard
                                    svg={event1Icon}
                                    number={data.addToCart}
                                    value={data.addToCartValue}
                                    rate={58}
                                    colour="bg-soft-info"
                                    progress="info"
                                    label="Add to Cart"
                                />
                                <ConversionCard
                                    svg={event2Icon}
                                    number={data.purchase}
                                    value={data.purchaseValue}
                                    rate={40}
                                    colour="bg-soft-primary"
                                    progress="primary"
                                    label="Purchase"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <PublisherPlatformChart name={'Publisher Platforms'} data={data}/>
                            <ConversionRateBarChart name={'Creative Conversion Rate'} data={data} colour="#427EEB" creatives={creatives.data.landingPages} rates={true}/>
                            <ConversionRateBarChart name={'Sales by Creative'} data={data} colour="#08B1BA" creatives={creatives.data.landingPages} rates={false}/>
                        </Row>

                        <Row>
                            <ConversionsChart data={data} />
                        </Row>
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}

export default Campaign