import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import Chart from 'react-apexcharts'

const CampaignCostChart = ({ data }) => {
    const [chartOptions, setChartOptions] = useState({})
    const [series, setSeries] = useState([0])
    const [chartKey, setChartKey] = useState(0)

    useEffect(() => {
        const spent = Math.round(data.spend)
        const formattedSpent = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(spent)

        const finalSpentValue = formattedSpent

        setChartOptions({
            labels: ['Amount Spent'],
            chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                    enabled: true
                },
            },
            colors: ['#08B1BA'],
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: '#3a57e81a',
                        show: true,
                        strokeWidth: '97%',
                        opacity: 1,
                        margin: 5,
                        dropShadow: {
                            enabled: false,
                            top: 0,
                            left: 0,
                            blur: 3,
                            opacity: 0.5
                        }
                    },
                    dataLabels: {
                        name: {
                            fontSize: '16px',
                            offsetY: 20,
                        },
                        value: {
                            offsetY: -25,
                            fontSize: '36px',
                            formatter: () => finalSpentValue
                        }
                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
        })

        setSeries([spent > 0 ? 75 : 0])

        // Change the chart key to force re-render
        setChartKey(prevKey => prevKey + 1)
    }, [data.spend])

    return (
        <Col sm="12">
            {chartOptions && (
                <Chart key={chartKey} options={chartOptions} series={series} type="radialBar" height="370" />
            )}
        </Col>
    )
}

export default CampaignCostChart